/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'align-center': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 1a.5.5 0 01.5.5V6h-1V1.5A.5.5 0 018 1m0 14a.5.5 0 01-.5-.5V10h1v4.5a.5.5 0 01-.5.5M2 7a1 1 0 011-1h10a1 1 0 011 1v2a1 1 0 01-1 1H3a1 1 0 01-1-1z"/>',
    },
});
